@import 'https://fonts.googleapis.com/css?family=Roboto:100,300';
@import 'https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.3.2/css/simple-line-icons.min.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css';
@import 'https://fonts.googleapis.com/css?family=Roboto+Mono:300,700';

.d-flex {
  display: flex;
}

.flex-0 {
  flex: 0%;
  transition: flex 0.5s ease;
}

.flex-1 {
  flex: 20%;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 80%;
  transition: flex 0.5s ease;
}

.p-3 {
  padding: 15px;
}

.p-1 {
  padding: 5px;
}

.mt-4 {
  margin-top: 20px;
}

.p-4 {
  padding: 20px;
}

.m-3 {
  margin: 15px;
}

.m-4 {
  margin: 20px;
}

.mb-4 {
  margin-bottom: 25px;
}

.text-align-center {
  text-align: center;
  text-align: -webkit-center;
}

select.store_locator_filter_regions {
  margin-bottom: 15px;
  background: #eee;
  padding: 10px;
  height: 55px;
  font-size: 16px !important;
  display: block;
  margin: 0 auto !important;
}

.h-100 {
  height: 100vh;
}

.list-points {
  margin: 15px;
}

.list-points-card {
  cursor: pointer;
  border: 1px solid #dadada;
  border-radius: 20px;
  background-color: #fff;
  margin-top: 10px;
}

.store-locator-row {
  justify-self: center;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.store_locator_name {
  font-size: 14px;
  line-height: 30px;
  font-weight: bold !important;
}

.store-locator-badge-success {
  color: #fff;
  background-color: #28a745;
}

.store-locator-badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.selected {
  border-color: red;
}

.left_column {
  background-color: #dc423c;
}

.custom-hr {
  border: 1px solid #eb5e5e;
}

.box-no-show {
  border: 1px solid #c53333;
  border-radius: 5px;
  text-align: center;
  padding: 15px;
  color: white;
  text-transform: uppercase;
  background-color: #cd3636;
}

/* selectbox css  start*/

.select {
  position: relative;
  overflow: hidden;
  display: block;
  height: 100%;
  border-bottom: 0px;
  border-radius: 3px;
  font-size: 12px;
  margin-top: 15px;
  box-shadow: 0px 1em 2em -1.5em rgba(0, 0, 0, 0.5);
}

.select>i.toggle {
  position: absolute;
  z-index: 4;
  right: 1.5em;
  top: 2em;
  color: #ccc;
}

.select .title,
.select .placeholder {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 1.5em 2em;
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.select>input {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  cursor: pointer;
}

.select>input:checked~i.toggle.icon-arrow-down {
  display: none;
}

.select>input:checked~i.toggle.icon-arrow-up {
  display: block;
}

.select>input:checked div.options label.option .title {
  display: none !important;
}

.select>input:not(:checked) {
  z-index: 4;
}

.select>input:not(:checked)~label.option>span.title {
  display: none;
}

.select>input:not(:checked)~i.toggle.icon-arrow-up {
  display: none;
}

.select>input:not(:checked)~i.toggle.icon-arrow-down {
  display: block;
}

.select>input:disabled {
  cursor: no-drop;
}

.select>span.placeholder {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  color: #999;
  border-top: 0px;
}

.select label.option {
  display: block;
  overflow: hidden;
  z-index: 1;
  width: 100%;
  transition: all 1s ease-out;
}

.select label.option span.title {
  position: relative;
  z-index: 2;
  transition: background 0.3s ease-out;
}

.select label.option span.title i.icon {
  padding-right: 8px;
  color: #92a8d1;
}

.select label.option span.title:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
}

.select label.option input {
  display: none;
}

.select label.option input:checked~span.title {
  position: absolute;
  display: block;
  z-index: 3;
  top: 0px;
  font-size: 12px;
  background: #fff;
  border-top: 0px;
  box-shadow: none;
  color: inherit;
  width: 100%;
}

.select label.option input:disabled~span.title {
  background: #f9f9f9 !important;
  color: #aaa;
}

.select label.option input:disabled~span.title:hover {
  color: #aaa;
  background: none;
  cursor: no-drop;
}

/* Select end*/

.info_logo {
  color: #e5e7eb;
  height: 20px;
  display: block;
  flex-direction: row;
}

/* media queries  start*/

@media screen and (min-width: 1024px) {
  .md-none {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .icon-menu {
    text-align: center;
  }

  .mobil_menu {
    border: 1px solid #fbfbfb00;
    background-color: #dc423c;
    color: white;
    text-align: left;
    width: 100%;
    position: absolute;
    z-index: 99;
    top: 0;
    display:flex;
    align-items: center;
    justify-content: center
  }

  .mobile-none {
    display: none;
  }
}

/* media queries  end*/

.spar {
  display: block !important;
  width: 100%;
  flex: 100% !important;
}

.d-none {
  display: none;
}

.searchbar {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  padding: 0px;
  outline: none;
  margin-top: 15px;
  background-color: white;
}

.searchbar::placeholder {
  color: #999;
}

.buttons button {
  padding: 6px 16px;
  border: 0;
  min-width: 70px;
  min-height: 36px;
  border-radius: 6px;
  color: #fff;
  margin-right: 8px;
  box-sizing: border-box;
  vertical-align: middle;
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(145, 158, 171, 0.2),
    0px 2px 2px 0px rgba(145, 158, 171, 0.14),
    0px 1px 5px 0px rgba(145, 158, 171, 0.12);
}

.filter-btn {
  background-color: #1890ff;
}

.clear-btn {
  background-color: #ff4842;
}

button:hover.filter-btn {
  background-color: #0c53b7;
  box-shadow: none;
  text-decoration: none;
  transition: 0.5s ease;
}

button:hover.clear-btn {
  background-color: #b72136;
  box-shadow: none;
  text-decoration: none;
  transition: 0.5s ease;
}

.buttons button:active {
  background-color: gray;
}

.custom-selector {
  background-color: white;
  font-size: 13px !important;
}

.custom-menu {
  padding: 15px !important;
  font-size: 13px !important;
  position: relative;
  z-index: 2;
  transition: background 0.3s ease-out;
  border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.custom-menu:hover {
  color: #fff;
  background: rgb(255 0 0 / 30%) !important;
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1) !important;
}


.gm-style-iw-tc {
  display: none !important;
}

.icon-close {
  text-align: center !important;
}

.img_logo {
  margin-top: 10px;
}