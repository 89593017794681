.wrapperClassName {
    display: flex;
    flex-direction: column-reverse;
    background-color: #FEFEFE;
    width: 99%;
    height: 100%;
    border-radius: 5px;
    padding-left: 10px;

}

.toolbarClassName {
    margin-bottom: 0%;
    border: none !important;
    height: 75px;
    align-items: center;
}

.rdw-option-wrapper {
    /* Burada kalın (bold) düğmenin çerçeve stilini değiştirin */
    border: 2px solid #EBEBEB;
    border-radius: 5px;
    width: 36px;
    height: 36px;
    /* Diğer stiller... */
}

.editorClassName {
    overflow-y: auto;
    height: 79%;
}

.text-editor-container {
    width: 95%;
    height: 30%;
    border: 1px solid #0000001A;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-right: 15px;
}

.text-editor-container-form {
    word-wrap: break-word; /* Uzun kelimeleri satır sonuna gelince kır */
    word-break: break-all; /* Alternatif olarak, uzun kelimeleri her yerde kırar */
    overflow-wrap: break-word; /* Modern tarayıcılarda benzer bir işlevsellik sağlar */
    white-space: pre-wrap; 
}

.rdw-image-modal {
    transform: translateY(-100%) !important;
}

.rdw-link-modal {
    transform: translateY(-100%) !important;
}
.rdw-dropdown-optionwrapper{
    width: 100% !important;
}