.css-1c44y0a-MuiTableCell-root {
  text-align: center;
}


.fs-sm{
  font-size: 12px;
}

.bt-lr-1{
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}


.country-spec .apexcharts-bar-series .apexcharts-series path:nth-child(1) {
  fill: #0666F6 /* İlk çubuğun rengi */
}

.country-spec .apexcharts-bar-series .apexcharts-series path:nth-child(2) {
  fill: #E0C6FD /* İkinci çubuğun rengi */
}

.country-spec .apexcharts-bar-series .apexcharts-series path:nth-child(3) {
  fill: #962DFF
}

.country-spec .apexcharts-bar-series .apexcharts-series path:nth-child(4) {
  fill:#C6D2FD /* Dördüncü çubuğun rengi */
}

.country-spec .apexcharts-grid-borders{
  display: none;
}
.country-spec .apexcharts-gridline{
  display: none;
}