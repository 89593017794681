.loading:before {
  content: '';
  background: #fafbfbb3;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading:after {
  content: url('./dual.gif');
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  color: black;
  text-align: center;
  font-weight: bold;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1024px) {
  .mobile{
    display: none !important;
  }
  
  .css-ndz54m-MuiTableCell-root{
    background-color: white !important;
  }

  .mobile-modal{
    width: Fixed (100px)px;
    height: Fixed (38px)px;
    padding: 10px 14px;
    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px 0px 0px 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    border: 1px solid #D0D5DD;
    margin-right: 2%;
  }  

  .mobile-modal button{
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #344054;
  }
}

@media only screen and (min-width: 1025px) {
  .desktop{
    display: none !important;
  }
}