@media screen and (max-width:768px) {
.mobile_horizantal{
    overflow-x: scroll;
}

.m-w-950{
    width: 950px !important;
}

.MuiDataGrid-columnHeaders{
    background-color: #eb33331a;
}

.mr-2{
    margin-right: 10px;
}
.ml-2{
    margin-left: 10px;
}
.gap-3{
    gap:5px;
   
   
}
.flex-direction-column{
    flex-direction: column;
}
 .d-flex{
    display:flex;
 }   
}

.special-short-answer .MuiAccordionSummary-contentGutters{
align-items: center;
}