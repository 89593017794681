@keyframes dragAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05); 
    }
    100% {
        transform: scale(1);
    }
}

.dragging {
    animation: dragAnimation 0.2s ease-in-out; 
}