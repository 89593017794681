.line {
    width: 18px;
    height: 0px;
    gap: 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    transform: -90 deg;
}

.delivered {
    width: Hug (117px)px;
    height: Fixed (30px)px;
    padding: 4px 12px 4px 10px;
    gap: 6px;
    border-radius: 5px;
    border: 1px solid #DCFAE6;
    opacity: 0px;
    color: #067647;
    background-color: #DCFAE6;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
}

.delivered2 {
    width: Hug (117px)px;
    height: Fixed (30px)px;
    padding: 4px 12px 4px 10px;
    gap: 6px;
    border-radius: 5px;
    border: 1px solid #D9D6FE;
    opacity: 0px;
    color: #5925DC;
    background-color: #EDEBFE; 
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
}

.delivered3 {
    width: Hug (117px)px;
    height: Fixed (30px)px;
    padding: 4px 12px 4px 10px;
    gap: 6px;
    border-radius: 5px;
    border: 1px solid #FECDCA;
    opacity: 0px;
    color: #B42318;
    background-color: #FDE8E8;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
}

.createbutton {
    width: Fixed (160px)px;
    height: Fixed (44px)px;
    color: #6B7280;
    border-radius: 5px;
    border: 1px solid #D1D5DB;
    opacity: 0px;
}