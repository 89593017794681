



@media screen and (max-width:768px) {
    .mobile_horizantal{
        overflow-x: scroll;
    }
    
    .m-w-950{
        width: 950px !important;
    }
    
    .MuiDataGrid-columnHeaders{
        background-color: #eb33331a;
    }
    
    .mr-2{
        margin-right: 10px;
    }
    .ml-2{
        margin-left: 10px;
    }
    .gap-3{
        gap:5px;
       
       
    }
    .flex-direction-column{
        flex-direction: column;
    }
     .d-flex{
        display:flex;
     }   
     .align-end{
        align-items: flex-end;
     }
     .mobile_point_tag{
        display: flex ;
        flex-wrap: wrap; 
        width: 146px;
        gap: 10px;

     }
    .mobile-p-0{
        padding: 0 !important;
    }
    }